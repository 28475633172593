
/* CSS初始化 */

body,p,h1,h2,h3,h4,h5,h6,ul,ol,dl,dt,dd,li {
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 14px;
	color: #2C2C2C;
}

a {
	color: #2C2C2C;
	text-decoration: none;
}

input,image {
	vertical-align: middle;
	border: 0 none;
	outline-style: none;
	padding: 0;
	margin: 0;
}

/* 清除浮动 */
.clearfix:after {
	content: "";
	height: 0;
	line-height: 0;
	display: block;
	clear: both;
	visibility: hidden;
}
/* 兼容IE浏览器 */
.clearfix {
	zoom: 1;
}


/* 版心1266 设计图1800 */
.typeArea {
	width: 1266px;
	margin: 0 auto;
}
*{
	box-sizing: border-box;
	font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Arial";
}

button{
	margin: 0;
	padding: 0;
	border: none;
	outline: none;
	background-color: unset;
}